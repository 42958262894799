import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useAuth } from '../../providers/auth'
import { Routes } from '../../types'
import DashboardPage from '../../wrappers/DashboardPage'

const PrivateDashboardRoute = ({ children, ...rest }: any) => {
  const auth = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          <DashboardPage>
            {children}
          </DashboardPage>
        ) : (
          <Redirect
            to={{
              pathname: Routes.Login,
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateDashboardRoute