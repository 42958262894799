import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3)
  },
  headerRow: {
    paddingBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  paperCentered: {
    textAlign: 'center',
    cursor: 'pointer'
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2)
  },
  icons: {
    fontSize: 74
  },
  pieChartWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  pieChart: {
    width: 200,
    height: 200
  }
}))

export default useStyles