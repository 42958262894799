import React from 'react'
import { Container, Grid, Paper, Typography } from '@material-ui/core'
import { PayloadSyncAdminOrgCouponsResponseV5 } from '@drops-developers/intersection'
import LocalPlayIcon from '@material-ui/icons/LocalPlay'
import PeopleIcon from '@material-ui/icons/People'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { PieChart } from 'react-minimal-pie-chart'

import useStyles from './styles'
import { Routes } from '../../types'
import { useAuth } from '../../providers/auth'
import Loading from '../../components/Loading'
import { useAdminList } from '../../api/react-query'

const Dashboard = () => {
  const classes = useStyles()
  const history = useHistory()
  const { invokeAdminApi } = useAuth()

  const { isLoading, data } = useAdminList(invokeAdminApi)

  const getNumberOfRedeemedCouponCodes = () => {
    return data!.coupons.filter(
      (coupon: PayloadSyncAdminOrgCouponsResponseV5) => coupon.isRedeemed
    ).length
  }

  return (
    <Container className={classes.container}>
      <Grid container justifyContent="space-between">
        <Grid className={classes.headerRow} item xs={12} md={10}>
          <Typography variant="h4">Dashboard</Typography>
          <Typography variant="body2">
            Welcome to the admin panel for your enterprise account. <br />
            On this page you’re able to track coupon code status and easily
            access other pages.
          </Typography>
        </Grid>
      </Grid>
      {isLoading && <Loading />}
      {!isLoading && data && (
        <>
          <Grid
            container
            justifyContent="space-between"
            xs={12}
            md={10}
            spacing={4}
          >
            <Grid className={classes.headerRow} item xs={12}>
              <Paper className={classes.paper}>
                <Grid container justifyContent="space-between">
                  <Grid className={classes.headerRow} item xs={12} md={6}>
                    <Typography variant="h6">Coupon code status</Typography>
                    <Typography variant="body1">
                      You've currently used{' '}
                      <b>{getNumberOfRedeemedCouponCodes()}</b> out of{' '}
                      {data.coupons.length} acquired coupon codes.
                    </Typography>
                  </Grid>
                  <Grid className={classes.headerRow} item xs={12} md={6}>
                    <div className={classes.pieChartWrapper}>
                      <PieChart
                        className={classes.pieChart}
                        data={[
                          {
                            title: 'Redeemed',
                            value: getNumberOfRedeemedCouponCodes(),
                            color: '#4ddc8b',
                          },
                          {
                            title: 'Not Redeemed',
                            value: data.coupons.length,
                            color: '#741b47',
                          },
                        ]}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            xs={12}
            md={10}
            spacing={4}
          >
            <Grid className={classes.headerRow} item xs={12} md={6}>
              <Paper
                className={classNames(classes.paper, classes.paperCentered)}
                onClick={() => history.push(Routes.UserList)}
              >
                <Typography variant="h6">Upgrade users to Premium</Typography>
                <PeopleIcon className={classes.icons} />
              </Paper>
            </Grid>
            <Grid className={classes.headerRow} item xs={12} md={6}>
              <Paper
                className={classNames(classes.paper, classes.paperCentered)}
                onClick={() => history.push(Routes.CouponCodeList)}
              >
                <Typography variant="h6">Track coupon codes</Typography>
                <LocalPlayIcon className={classes.icons} />
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  )
}

export default Dashboard
