export interface WordBaseData {
  wordId: number
  topicIds: number[]
  unitState: string
  availableLanguages: string
  enTranslation: string | undefined
}

const loadJSONs = (): WordBaseData[] => {
  const finalData = []
  let currentJson
  for (let index = 0; index <= 8250; index++) {
    try {
      currentJson = require(`../contentData/words/${index}.json`)
      if (currentJson.unitState === 'Production ready') {
        finalData.push(currentJson)
      }
    } catch (err) {}
  }
  return finalData
}

export default loadJSONs
