import React, { useState } from 'react'
import { TextField, Button, useMediaQuery, useTheme, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { useHistory, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { useAuth } from '../../providers/auth'

import logoDrops from "../../assets/logo/intro-logo-kahoot.png"
import useStyles from './styles'

const Login = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const classes = useStyles()
  const theme = useTheme()
  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  const history = useHistory()
  const location = useLocation()
  const auth = useAuth()

  const { from } : any = location.state || { from: { pathname: "/" } }

  const login = () => {
    if (username && password) {
      auth.signin(username, password, () => {
        history.replace(from)
      })
    }
    else {
      alert("Please enter username and password")
    }
  }

  const introTop = (
    <>
      <Typography variant="h4" className={classes.title}>Drops Administration</Typography>
      <Typography variant="body1" className={classes.text}>Please login with your business partner credentials.</Typography>
    </>
  )

  const introBottom = (
    <>
      <Typography variant="h5" className={classes.title}>Interested in Partnering?</Typography>
      <Typography variant="body1" className={classes.text}>Contact our team via business@languagedrops.com!</Typography>
    </>
  )

  return (
    <div className={classes.pageLogin}>
      <Grid container justifyContent="center" alignContent="space-between" className={classes.pageLoginContainer}>
        <Grid item xs={12}>
          <header className={classes.header}>
            <img className={classes.logoDrops} src={logoDrops} alt="Drops logo" />
          </header>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container alignItems="center" >
            <Grid item xs={12} md={6}>
              <div className={classes.intro}>
                {introTop}
                {!isSM ? <div className={classes.divider} /> : null}
                {!isSM ? introBottom : null}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justifyContent="center" >
                <Grid item xs={10} md={8}>
                  <Typography variant="h5" className={classes.title}>Sign in</Typography>
                  <Paper className={classes.paper}>
                    <form className={classes.formLogin} noValidate autoComplete="off">
                      <div className={classes.formItem}>
                        <TextField id="username" label="Email" onChange={e => setUsername(e.target.value)} required fullWidth />
                      </div>
                      <div className={classes.formItem}>
                        <TextField id="password" label="Password" onChange={e => setPassword(e.target.value)} required fullWidth type="password" />
                      </div>
                      <Button onClick={login} variant="contained" color="primary" fullWidth>
                        Sign in
                      </Button>
                    </form>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {isSM ? (
              <Grid item xs={12} md={6}>
                <div className={classes.intro}>
                  {introBottom}
                </div>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <footer className={classNames(classes.footer, classes.text)}>
            <p>© Drops. All rights reserved. | <a className={classes.link} href="https://languagedrops.com/terms" target="_blank" rel="noreferrer">Terms & Conditions</a> | <a className={classes.link} href="https://languagedrops.com/privacypolicy" target="_blank" rel="noreferrer">Privacy Policy</a></p>
          </footer>
        </Grid>
      </Grid>
    </div>
  )
}

export default Login