import {
  PayloadSyncAdminRequestV5,
  PayloadGetOrganizationMetricsRequestV5,
  PayloadGetOrganizationMetricsResponseV5,
  PayloadSyncAdminResponseV5,
  PayloadSyncOrganizationTopicsRequestV5,
  PayloadSyncOrganizationTopicsResponseV5,
  PayloadDeleteOrganizationTopicResponseV5,
  PayloadDeleteOrganizationTopicRequestV5,
  PayloadDeleteOrganizationTopicWordRequestV5,
  PayloadDeleteOrganizationTopicWordResponseV5,
} from '@drops-developers/intersection'

export enum NetworkEnvironment {
  dev = 'dev',
  staging = 'staging',
  preprod = 'preprod',
  prod = 'prod',
}

const getApiKeyForStage = (environment: NetworkEnvironment): string => {
  switch (environment) {
    case NetworkEnvironment.dev:
      return 'rb13Q2whQZ8ZXLLrZmG4N833aSWVpkky5KR3Xtsj'
    case NetworkEnvironment.staging:
      return 'SKefcpqOty919BDAn2ITZ6TdKsh3yhe03dd7gjhw'
    case NetworkEnvironment.preprod:
      return 'njTXygxoxp95OyuOVcWhk8tykYB8dSVEaL4vZtku'
    case NetworkEnvironment.prod:
      return 'd9Dld3k1g74IAWuVAlalQ64hXv29BoZEaNLSVpSj'
  }
}

const invokeGlobalApiGateway = async (
  endPoint: string,
  environment: NetworkEnvironment | undefined,
  requestPayload: object
): Promise<any> => {
  const host = `https://api-${environment}.languagedrops.com/`
  const headersToSend = {
    Accept: 'application/json',
    'x-api-key': getApiKeyForStage(
      environment ? environment : NetworkEnvironment.dev
    ),
    'Content-Type': 'application/json',
  }
  const url = host + endPoint

  const request = {
    method: 'POST',
    url: url,
    headers: headersToSend,
    data: JSON.stringify({ ...requestPayload }),
  }

  try {
    const response = await fetch(url, {
      headers: request.headers,
      method: request.method,
      body: request.data,
    })

    const t = await response.json()

    if (response.ok) {
      return t
    } else {
      throw new Error(t)
    }
  } catch (err) {
    return err
  }
}

export const syncAdminV5 = async (
  payload: PayloadSyncAdminRequestV5
): Promise<PayloadSyncAdminResponseV5> => {
  return invokeGlobalApiGateway(
    'syncAdminV5',
    process.env.REACT_APP_ENVIRONMENT as NetworkEnvironment,
    payload
  )
}

export const getOrganizationMetricsV5 = async (
  payload: PayloadGetOrganizationMetricsRequestV5
): Promise<PayloadGetOrganizationMetricsResponseV5> => {
  return invokeGlobalApiGateway(
    'getOrganizationMetricsV5',
    process.env.REACT_APP_ENVIRONMENT as NetworkEnvironment,
    payload
  )
}

export const syncOrganizationTopicsV5 = async (
  payload: PayloadSyncOrganizationTopicsRequestV5
): Promise<PayloadSyncOrganizationTopicsResponseV5> => {
  return invokeGlobalApiGateway(
    'syncOrganizationTopicsV5',
    process.env.REACT_APP_ENVIRONMENT as NetworkEnvironment,
    payload
  )
}

export const deleteOrganizationTopicV5 = async (
  payload: PayloadDeleteOrganizationTopicRequestV5
): Promise<PayloadDeleteOrganizationTopicResponseV5> => {
  return invokeGlobalApiGateway(
    'deleteOrganizationTopicV5',
    process.env.REACT_APP_ENVIRONMENT as NetworkEnvironment,
    payload
  )
}

export const deleteOrganizationTopicWordV5 = async (
  payload: PayloadDeleteOrganizationTopicWordRequestV5
): Promise<PayloadDeleteOrganizationTopicWordResponseV5> => {
  return invokeGlobalApiGateway(
    'deleteOrganizationTopicWordV5',
    process.env.REACT_APP_ENVIRONMENT as NetworkEnvironment,
    payload
  )
}
