import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  pageLogin: {
    background: theme.palette.secondary.main,
    color: 'white',
    overflow: 'auto'
  },
  pageLoginContainer: {
    minHeight: '100vh',
    backgroundImage: `url("https://assets.website-files.com/5ccc2cf8c370bd68bba42321/5da7443cab7e3f41dca8a613_hero_homepage_bg_small.jpg")`,
  },
  formLogin: {
    backgroundColor: 'white'
  },
  formItem: {
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
  },
  intro: {
    padding: theme.spacing(2),
  },
  logoDrops: {
    width: 90,
    height: 'auto'
  },
  header: {
    padding: 48,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
  },
  title: {
    '&:first-child': {
      marginTop: 0
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
  },
  text: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '14px',
    lineHeight: '21px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
  },
  divider: {
    height: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    marginTop: 32,
    marginBottom: 32
  },
  footer: {
    textAlign: 'center'
  },
  link: {
    color: 'rgba(255, 255, 255, 0.5)',
    textDecoration: 'none',
  },
}))

export default useStyles