import { Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import classNames from 'classnames'
import { PieChart } from 'react-minimal-pie-chart';
import { getHumanReadableLanguageName, KeyedDictionary, LanguageISO } from '@drops-developers/intersection';

import useStyles from './styles'
import { pieChartColors } from '../../constants/styles';

interface MetricItemPieChartProps {
  title: string,
  data: KeyedDictionary<string, number>
}

interface ChartDataItem {
  title: string,
  value: number,
  color: string,
}

const compareDesc = ( a: ChartDataItem, b: ChartDataItem ) => {
  if ( a.value > b.value ){
    return -1;
  }
  if ( a.value < b.value ){
    return 1;
  }
  return 0;
}

const MetricItemPieChart = ({ title, data } : MetricItemPieChartProps) => {
  const classes = useStyles()

  const chartData = Object.keys(data).map((key, i) => {
    const dataItem: ChartDataItem = { title: key, value: data[key] || 0, color: pieChartColors[i] }
    return dataItem
  })

  const sortedChartData = [...chartData].sort(compareDesc)

  return (
    <Grid className={classes.headerRow} item xs={12} md={12}>
      <Paper className={classNames(classes.paper, classes.paperCentered)}>
        <Typography variant="h6">{ title }</Typography>
        <Grid container justifyContent="center" className={classes.pieChartWrapper}>
          <Grid item xs={12} md={4}>
            <PieChart
              className={classes.pieChart}
              data={sortedChartData}
              label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%' }
              labelStyle={{
                fontSize: 4,
                fill: "white"
              }}
            />
          </Grid>
          <Grid className={classes.pieChartInfo} item xs={12} md={2}>
            {sortedChartData.map(item => (
              <div className={classes.languageItem} key={item.title}>
                <div className={classes.languageItemDot} style={{ backgroundColor: item.color }} />
                <Typography variant="body2">{getHumanReadableLanguageName(item.title.toUpperCase() as LanguageISO)}</Typography>
              </div>
            ))}
          </Grid>
        </Grid>
        <Typography className={classes.languageDistributionCaption} variant="caption">(total learners across languages)</Typography>
      </Paper>
    </Grid>
  )
}

export default MetricItemPieChart