import { Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import classNames from 'classnames'

import useStyles from './styles'

interface MetricItemSmallProps {
  title: string,
  data: number
}

const MetricItemSmall = ({ title, data } : MetricItemSmallProps) => {
  const classes = useStyles()

  return (
    <Grid className={classes.headerRow} item xs={12} md={4}>
      <Paper className={classNames(classes.paper, classes.paperCentered)}>
        <Typography variant="h6">{ title }</Typography>
        <Typography className={classes.metricData}>{ data }</Typography>
        <Typography variant="caption">(average per user)</Typography>
      </Paper>
    </Grid>
  )
}

export default MetricItemSmall