import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  headerRow: {
    paddingBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  paperCentered: {
    textAlign: 'center',
  },
  metricData: {
    fontSize: 42
  },
  bold: {
    fontWeight: 'bold'
  },
}))

export default useStyles