import { makeStyles } from '@material-ui/core/styles'
import { titleRowHeight } from '../../constants/styles'

const useStyles = makeStyles(theme => ({
  logo: {
    width: 65,
    height: 30,
    cursor: 'pointer',
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  titleRow: {
    backgroundColor: "white",
    color: "black",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: titleRowHeight,
    lineHeight: titleRowHeight + "px",
  },
  title: {
    marginBottom: 0,
    display: "inline",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  side: {
    display: "flex",
    alignItems: "center"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  }
}))

export default useStyles