export enum AdminApiAction {
  GetList = 'getList',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export enum AdminApiQueries {
  GetList = 'getList',
}

export enum MetricsApiQueries {
  GetOrganizationMetrics = 'getOrganizationMetrics',
}

export enum CustomTopicsApiQueries {
  GetCustomTopics = 'getCustomTopics',
}

export enum Routes {
  Dashboard = '/',
  Login = '/login',
  UserList = '/users',
  UserCreate = '/users/create',
  UserUpdate = '/users/edit',
  CouponCodeList = '/coupon-codes',
  Insights = '/insights',
  CustomTopics = '/custom-topics',
}
