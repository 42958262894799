import React, { useState } from 'react'
import { Container, Grid, Typography, Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useHistory } from 'react-router-dom'

import useStyles from './styles'
import { useAuth } from '../../../providers/auth'
import { AdminApiAction, Routes } from '../../../types'

const UserEdit = () => {
  const classes = useStyles()
  const history = useHistory()
  const { invokeAdminApi } = useAuth()

  const isCreate = history.location.pathname === Routes.UserCreate
  const locationState = history.location.state as any

  const [email, setEmail] = useState(isCreate ? "" : locationState?.user?.email)
  const [group, setGroup] = useState(isCreate ? "" : "default")

  if (!locationState || (!isCreate && !locationState.user)) {
    history.push(Routes.UserList)
    return null
  }

  const allGroups = locationState.groups.map((stateGroup: { name: string }) => ({
    id: stateGroup.name,
    name: stateGroup.name
  }))

  const handleSave = async () => {
    if (!email) {
      alert("Please enter email")
      return
    }

    const payload: any = isCreate ? {
      action: AdminApiAction.Create,
      data: {
        email,
        Group: group
      }
    } : {
      action: AdminApiAction.Update,
      data: {
        email,
        id: locationState.user.userId,
      }
    }
    const response = await invokeAdminApi(payload)

    if (response instanceof Error) {
      alert(JSON.parse(response.message).details || 'Unexpected error')
      // TODO: material-ui error alert
    }

    history.push(Routes.UserList)
    // TODO: material-ui success alert
  }

  return (
    <Container className={classes.container}>
      <Typography variant="h5" className={classes.title}>{isCreate ? "Create" : "Edit"} User</Typography>
      <Grid container alignContent="space-between">
        <Grid item xs={12} md={4}>
        <form noValidate autoComplete="off">
          <div className={classes.formItem}>
            <TextField id="email" label="Email" variant="filled" required fullWidth value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className={classes.formItem}>
            <Autocomplete
              id="groups"
              options={allGroups}
              value={allGroups[0]}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => setGroup(value ? value.id : "")}
              renderInput={(params) => <TextField {...params} label="Groups" variant="filled" required />}
            />
          </div>
          <div className={classes.buttons}>
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          </div>
        </form>
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserEdit