import React, { createContext, useContext, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import {
  PayloadDeleteOrganizationTopicRequestV5,
  PayloadDeleteOrganizationTopicResponseV5,
  PayloadDeleteOrganizationTopicWordRequestV5,
  PayloadDeleteOrganizationTopicWordResponseV5,
  PayloadGetOrganizationMetricsRequestV5,
  PayloadSyncAdminRequestV5,
  PayloadSyncOrganizationTopicsRequestV5,
  PayloadSyncOrganizationTopicsResponseV5,
} from '@drops-developers/intersection'
import {
  deleteOrganizationTopicV5,
  deleteOrganizationTopicWordV5,
  getOrganizationMetricsV5,
  syncAdminV5,
  syncOrganizationTopicsV5,
} from '../../api'
import {
  AdminApiInternalPayload,
  CustomTopicsApiInternalPayload,
  DeleteOrganizationTopicInternalPayload,
  DeleteOrganizationTopicWordInternalPayload,
} from '../../types/auth'

interface AuthContext {
  user: firebase.User | null
  signin: (username: string, password: string, cb: () => void) => Promise<void>
  signout: () => Promise<void>
  isLoading: boolean
  invokeAdminApi: (payload: AdminApiInternalPayload) => Promise<any>
  invokeMetricsApi: (
    payload: PayloadGetOrganizationMetricsRequestV5
  ) => Promise<any>
  invokeCustomTopicsApi: (
    payload: CustomTopicsApiInternalPayload
  ) => Promise<PayloadSyncOrganizationTopicsResponseV5>
  invokeDeleteOrganizationTopicV5: (
    payload: DeleteOrganizationTopicInternalPayload
  ) => Promise<PayloadDeleteOrganizationTopicResponseV5>
  invokeDeleteOrganizationTopicWordV5: (
    payload: DeleteOrganizationTopicWordInternalPayload
  ) => Promise<PayloadDeleteOrganizationTopicWordResponseV5>
}

export const useAuth = () => {
  return useContext<AuthContext>(authContext)
}

function useProvideAuth() {
  const [user, setUser] = useState<firebase.User | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser)
      }

      setIsLoading(false)
    })
  }, [])

  const signin = async (username: string, password: string, cb: () => {}) => {
    const data = await firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message

        if (errorCode === 'auth/wrong-password') {
          alert('Wrong password.')
        } else {
          alert(errorMessage)
        }

        throw error
      })

    if (data != null) {
      setUser(data.user)
      cb()
    }
  }

  const signout = async () => {
    await firebase.auth().signOut()
    setUser(null)
  }

  const invokeAdminApi = async (payload: AdminApiInternalPayload) => {
    if (user == null || user.email == null) {
      throw new Error('Not Authenticated')
    }

    const payloadWithAdminEmail: PayloadSyncAdminRequestV5 = {
      email: user.email,
      ...payload,
    }
    return await syncAdminV5(payloadWithAdminEmail)
  }

  const invokeMetricsApi = async (
    payload: PayloadGetOrganizationMetricsRequestV5
  ) => {
    return await getOrganizationMetricsV5(payload)
  }

  const invokeCustomTopicsApi = async (
    payload: CustomTopicsApiInternalPayload
  ) => {
    if (user == null || user.email == null) {
      throw new Error('Not Authenticated')
    }

    const payloadWithAdminEmail: PayloadSyncOrganizationTopicsRequestV5 = {
      email: user.email,
      ...payload,
    }
    return await syncOrganizationTopicsV5(payloadWithAdminEmail)
  }

  const invokeDeleteOrganizationTopicV5 = async (
    payload: DeleteOrganizationTopicInternalPayload
  ) => {
    if (user == null || user.email == null) {
      throw new Error('Not Authenticated')
    }

    const payloadWithAdminEmail: PayloadDeleteOrganizationTopicRequestV5 = {
      email: user.email,
      ...payload,
    }
    return await deleteOrganizationTopicV5(payloadWithAdminEmail)
  }

  const invokeDeleteOrganizationTopicWordV5 = async (
    payload: DeleteOrganizationTopicWordInternalPayload
  ) => {
    if (user == null || user.email == null) {
      throw new Error('Not Authenticated')
    }

    const payloadWithAdminEmail: PayloadDeleteOrganizationTopicWordRequestV5 = {
      email: user.email,
      ...payload,
    }

    return await deleteOrganizationTopicWordV5(payloadWithAdminEmail)
  }

  return {
    user,
    signin,
    signout,
    isLoading,
    invokeAdminApi,
    invokeMetricsApi,
    invokeCustomTopicsApi,
    invokeDeleteOrganizationTopicV5,
    invokeDeleteOrganizationTopicWordV5,
  }
}

const authContext = createContext({} as any)

interface ProvideAuthProps {
  children: JSX.Element
}

export const ProvideAuth = ({ children }: ProvideAuthProps) => {
  const auth = useProvideAuth()
  return (
    <authContext.Provider value={auth}>
      {!auth.isLoading && children}
    </authContext.Provider>
  )
}
