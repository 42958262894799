import {
  Container,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useQuery } from 'react-query'

import useStyles from './styles'
import { MetricsApiQueries } from '../../types'
import { useAuth } from '../../providers/auth'
import MetricItemSmall from '../../components/MetricItemSmall'
import Loading from '../../components/Loading'
import MetricItemPieChart from '../../components/MetricItemPieChart'
import MaterialTable, { MTableToolbar } from 'material-table'
import { PayloadSyncAdminResponseV5 } from '@drops-developers/intersection'
import { format } from 'date-fns'
import { useAdminList } from '../../api/react-query'

enum InfoType {
  WordsLearnt = 'wordsLearnt',
  LastActiveAt = 'lastActiveAt',
  StreakRecord = 'streakRecord',
}

const formatData = (infoType: InfoType, data: string | number | null) => {
  switch (infoType) {
    case InfoType.LastActiveAt:
      return data ? format(Number(data), 'yyyy/MM/dd HH:mm') : '-'
    default:
      return data
  }
}

const getTableData = (
  displayInfo: InfoType,
  data: PayloadSyncAdminResponseV5
) => {
  return data.users
    .map((row) => ({
      email: row.email,
      data: formatData(displayInfo, row[displayInfo]),
    }))
    .sort((a, b) => {
      if (a.data && b.data && a.data > b.data) {
        return -1
      }
      if (a.data && b.data && a.data < b.data) {
        return 1
      }
      return 0
    })
    .map((row, index) => ({
      ...row,
      index: index + 1,
    }))
}

const Insights = () => {
  const classes = useStyles()
  const { invokeMetricsApi, invokeAdminApi } = useAuth()
  const [displayInfo, setDisplayInfo] = useState(InfoType.WordsLearnt)

  const { isLoading: isLoadingList, data: dataList } =
    useAdminList(invokeAdminApi)

  const {
    isIdle,
    isLoading: isLoadingMetrics,
    data: dataMetrics,
  } = useQuery(
    MetricsApiQueries.GetOrganizationMetrics,
    async () => {
      const payload: any = {
        organizationId: dataList!.organizationId,
      }
      const response = await invokeMetricsApi(payload)

      if (response instanceof Error) {
        throw new Error(response.message)
      }
      return response
    },
    {
      enabled: !!dataList?.organizationId,
    }
  )

  const isLoading = isIdle || isLoadingList || isLoadingMetrics

  const dataToDisplay = [
    {
      name: 'avgWordsLearned',
      title: 'Words learned',
    },
    {
      name: 'avgStreakRecord',
      title: 'Streak record',
    },
    {
      name: 'avgLanguagesLearned',
      title: 'Languages learned',
    },
    {
      name: 'avgNumberOf100PercentAccuracySessions',
      title: '100% accuracy sessions',
    },
  ]

  const metricsElementsSmall = []

  if (dataMetrics) {
    for (const data of dataToDisplay) {
      if (!dataMetrics[data.name]) continue
      metricsElementsSmall.push(
        <MetricItemSmall
          key={data.name}
          title={data.title}
          data={dataMetrics[data.name]}
        />
      )
    }
  }

  const columns = [
    {
      field: 'index',
      cellStyle: {
        width: '5%',
      },
    },
    {
      field: 'email',
    },
    {
      field: 'data',
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: 'auto',
      },
    },
  ]

  return (
    <Container className={classes.container}>
      <Grid container justifyContent="space-between">
        <Grid className={classes.headerRow} item xs={12} md={10}>
          <Typography variant="h4">Insights</Typography>
          <Typography variant="body2">
            The overall usage of Drops from your active users. <br />
          </Typography>
        </Grid>
      </Grid>
      {isLoading && <Loading />}
      {!isLoading &&
        dataMetrics &&
        (metricsElementsSmall.length ? (
          <Grid container justifyContent="space-between" spacing={4}>
            {metricsElementsSmall}
          </Grid>
        ) : (
          <Typography variant="body1">No data to show yet</Typography>
        ))}
      {!isLoading &&
        Object.keys(dataMetrics.languagesLearnedDistribution).length && (
          <Grid container justifyContent="space-between" spacing={4}>
            <MetricItemPieChart
              title="Languages distribution"
              data={dataMetrics.languagesLearnedDistribution}
            />
          </Grid>
        )}
      {!isLoading && dataList && (
        <MaterialTable
          columns={columns}
          data={getTableData(displayInfo, dataList)}
          title=""
          options={{
            search: true,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            rowStyle: {
              fontFamily: 'TT Norms Pro Medium, Arial',
            },
          }}
          components={{
            Toolbar: (props) => (
              <div className={classes.toolbar}>
                <Typography variant="h4" className={classes.tableTitle}>
                  Users
                </Typography>
                <MTableToolbar {...props} />
                <Select
                  labelId="display-info-select-label"
                  id="display-info-select"
                  value={displayInfo}
                  label="Display Info"
                  onChange={(e) => setDisplayInfo(e.target.value as InfoType)}
                  className={classes.selector}
                >
                  <MenuItem value={InfoType.WordsLearnt}>
                    Most words learnt
                  </MenuItem>
                  <MenuItem value={InfoType.LastActiveAt}>Last active</MenuItem>
                  <MenuItem value={InfoType.StreakRecord}>
                    Streak record
                  </MenuItem>
                </Select>
              </div>
            ),
            Container: (props) => (
              <div className={classes.individualInsights}>
                <Paper {...props} elevation={2} />
              </div>
            ),
          }}
        />
      )}
    </Container>
  )
}

export default Insights
