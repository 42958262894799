import React, { FC } from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'
import PeopleIcon from '@material-ui/icons/People'
import PieChartIcon from '@material-ui/icons/PieChart'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import classNames from 'classnames'

import useStyles from './styles'
import { useHistory, useLocation } from 'react-router-dom'
import { Routes } from '../../types'

interface NavigationProps {
  open: boolean
  toggleDrawer: () => void
}

interface NavigationItem {
  name: string
  route: string
  icon: JSX.Element
}

const navigationItems: NavigationItem[] = [
  {
    name: 'Users',
    route: Routes.UserList,
    icon: <PeopleIcon />,
  },
  {
    name: 'Licenses',
    route: Routes.CouponCodeList,
    icon: <ConfirmationNumberIcon />,
  },
  {
    name: 'Insights',
    route: Routes.Insights,
    icon: <PieChartIcon />,
  },
  {
    name: 'Custom Topics',
    route: Routes.CustomTopics,
    icon: <AddCircleIcon />,
  }
]

const Navigation: FC<NavigationProps> = ({ open, toggleDrawer }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSM = useMediaQuery(theme.breakpoints.down('sm'))
  const history = useHistory()
  const location = useLocation()

  const handleClick = (navigationItem: NavigationItem) => {
    history.push(navigationItem.route)
    if (isSM) {
      toggleDrawer()
    }
  }

  const isSelected = (navigationItem: NavigationItem) =>
    navigationItem.route === location.pathname

  return (
    <Drawer
      variant={isSM ? 'temporary' : 'persistent'}
      open={isSM ? open : true}
      onClose={toggleDrawer}
    >
      {!isSM ? <div className={classes.toolbar} /> : null}
      <div
        className={classNames(
          classes.drawer,
          open ? classes.drawerOpen : classes.drawerClose
        )}
        role="presentation"
        onClick={() => {}}
        onKeyDown={() => {}}
      >
        <List>
          {navigationItems.map((navigationItem) => (
            <ListItem
              button
              key={navigationItem.name}
              onClick={() => handleClick(navigationItem)}
              selected={isSelected(navigationItem)}
            >
              <ListItemIcon>{navigationItem.icon}</ListItemIcon>
              <ListItemText primary={navigationItem.name} />
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  )
}

export default Navigation
