import React from 'react'
import { Button, Container, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { PayloadSyncAdminOrgUserResponseV5 } from '@drops-developers/intersection'
import MaterialTable from 'material-table'

import { useAuth } from '../../providers/auth'
import useStyles from './styles'
import { Routes } from '../../types'
import Loading from '../../components/Loading'
import { useAdminList } from '../../api/react-query'

const Users = () => {
  const { invokeAdminApi } = useAuth()
  const classes = useStyles()
  const history = useHistory()

  const { isLoading, data } = useAdminList(invokeAdminApi)

  const handleTableRowClick = (
    event: any,
    user: PayloadSyncAdminOrgUserResponseV5 | undefined
  ) => {
    if (!user) return

    history.push({
      pathname: Routes.UserUpdate + '/' + user.userId,
      state: {
        user,
        groups: data?.groups,
      },
    })
  }

  const handleCreate = () => {
    history.push({
      pathname: Routes.UserCreate,
      state: {
        groups: data?.groups,
      },
    })
  }

  const columns = [
    {
      field: 'email',
      title: 'Email',
    },
  ]

  return (
    <Container className={classes.container}>
      <Grid container justifyContent="space-between">
        <Grid className={classes.headerRow} item xs={12} md={8}>
          <Typography variant="h4">Users</Typography>
          <Typography variant="body2">
            Below are the registered Premium users on your account. <br />
            Before adding a user, please make sure the email is already
            registered with Drops.
          </Typography>
        </Grid>
        <Grid className={classes.headerRow} item>
          <Button onClick={handleCreate} variant="contained" color="primary">
            Add
          </Button>
        </Grid>
      </Grid>
      {isLoading && <Loading />}
      {!isLoading && data && (
        <MaterialTable
          columns={columns}
          data={data.users}
          title=""
          options={{
            search: true,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            rowStyle: {
              fontFamily: 'TT Norms Pro Medium, Arial',
            },
          }}
          onRowClick={handleTableRowClick}
        />
      )}
    </Container>
  )
}

export default Users
