import {
  Box,
  Button,
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { FC } from 'react'
import useStyles from '../styles'
import { OrganizationTopic } from '@drops-developers/intersection'

interface InitialStepProps {
  goToChangeTopic: (selectedTopic: OrganizationTopic | null) => void
  topics: OrganizationTopic[]
  orgName: string
}

const InitialStep: FC<InitialStepProps> = ({
  goToChangeTopic,
  topics,
  orgName,
}) => {
  const classes = useStyles()

  const chooseTopic = (topic: OrganizationTopic) => {
    goToChangeTopic(topic)
  }

  return (
    <Container className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography className={classes.orgName} variant="h5">
            {orgName}
          </Typography>
          <Typography variant="body2" className={classes.orgDesc}>
            Your organisation name
          </Typography>
        </Box>
      </Box>
      <Divider />

      <Box marginTop={4}>
        <Typography variant="h5" className={classes.topicTitle}>
          Topics
        </Typography>
        <List className={classes.topicsList} disablePadding>
          {topics.map((topic) => {
            return (
              <div key={topic.id}>
                <ListItem
                  className={classes.listItem}
                  button
                  onClick={() => chooseTopic(topic)}
                >
                  <ListItemText
                    primary={topic.title}
                    secondary={`${topic.words.length} terms`}
                  />
                  <ArrowRightIcon />
                </ListItem>
                <Divider />
              </div>
            )
          })}
        </List>
        {topics.length >= 10 && (
          <Box className={classes.wordLimitContainer}>
            <Typography className={classes.wordLimitText} variant="body1">
              Only 10 topics allowed
            </Typography>
          </Box>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={topics.length >= 10}
          onClick={() => goToChangeTopic(null)}
        >
          NEW TOPIC
        </Button>
      </Box>
    </Container>
  )
}

export default InitialStep
