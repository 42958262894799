import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3)
  },
  formItem: {
    paddingBottom: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(3),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
}))

export default useStyles