export const drawerWidth = 240
export const titleRowHeight = 64

export const pieChartColors = [
  '#fbbf66',
  '#282818',
  '#d04f31',
  '#ffe485',
  '#fe843e',
  '#9d267a',
  '#df3cbe',
  '#6e65e3',
  '#26f7a5',
  '#c3ec75',
  '#3efb23',
  '#adf761',
  '#5293c1',
  '#8482c6',
  '#9ea72f',
  '#9bac4e',
  '#69310',
  '#a2c20b',
  '#2d9c20',
  '#bb76ad',
  '#8b5a71',
  '#8d6ae0',
  '#f01d00',
  '#91f8e',
  '#9544e2',
  '#6d6271',
  '#67b2ee',
  '#5bad7e',
  '#a59a4',
  '#ac5405',
  '#f2407f',
  '#2a6026',
  '#859426',
  '#2fec50',
  '#5edea1',
  '#5f22ff',
  '#8be21c',
  '#f2b241',
  '#b44890',
  '#43dc03',
  '#a089fa',
  '#3ca199',
  '#e1e21e',
  '#d607b7',
  '#76db22',
  '#2e0fd8',
  '#d92a5e',
  '#42503',
  '#efdabb',
  '#d440fb',
]
