import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { FC } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import CloseIcon from '@material-ui/icons/Close'
import DehazeIcon from '@material-ui/icons/Dehaze'
import { WordBaseData } from '../../../utils/loadJSONs'
import useStyles from '../styles'

interface DraggableListProps {
  wordsList: WordBaseData[]
  changeOrder: (newOrdered: WordBaseData[]) => void
  removeWord: (wordId: number) => void
}

const DraggableList: FC<DraggableListProps> = ({
  wordsList,
  changeOrder,
  removeWord,
}) => {
  const classes = useStyles()

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (source.index !== destination.index) {
      const newItems = [...wordsList]
      const [removed] = newItems.splice(source.index, 1)
      newItems.splice(destination.index, 0, removed)
      changeOrder(newItems)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(providedOuter) => (
          <List>
            <div ref={providedOuter.innerRef}>
              {wordsList.map((word, index) => (
                <Draggable
                  key={word.wordId}
                  draggableId={word.wordId.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <ListItem className={classes.listItem}>
                        <ListItemText primary={word.enTranslation} />
                        <IconButton
                          style={{ marginRight: '8px' }}
                          onClick={() => removeWord(word.wordId)}
                        >
                          <CloseIcon style={{ fill: '#000000' }} />
                        </IconButton>
                        <Box display="flex" {...provided.dragHandleProps}>
                          <DehazeIcon />
                        </Box>
                      </ListItem>
                    </div>
                  )}
                </Draggable>
              ))}
              {providedOuter.placeholder}
            </div>
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableList
