import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import {
  PayloadSyncAdminOrgUserResponseV5,
  PayloadSyncAdminResponseV5,
} from '@drops-developers/intersection'
import MaterialTable from 'material-table'

import { useAuth } from '../../providers/auth'

import useStyles from './styles'
import Loading from '../../components/Loading'
import { useAdminList } from '../../api/react-query'

const getUserEmailById = (
  users: PayloadSyncAdminOrgUserResponseV5[],
  redeemedByUserId: string
) => {
  const matchingUsers = users.filter((user) => user.userId === redeemedByUserId)

  if (matchingUsers.length) {
    return matchingUsers[0].email
  }

  return 'User not found'
}

const getTableData = (data: PayloadSyncAdminResponseV5) => {
  return data.coupons.map((row) => ({
    couponCode: row.couponCode,
    isRedeemed: row.isRedeemed ? 'Assigned' : 'Unassigned',
    redeemedByUserEmail: row.redeemedByUserId
      ? getUserEmailById(data.users, row.redeemedByUserId)
      : '',
  }))
}

const CouponCodes = () => {
  const { invokeAdminApi } = useAuth()
  const classes = useStyles()

  const { isLoading, data } = useAdminList(invokeAdminApi)

  const columns = [
    {
      field: 'couponCode',
      title: 'Code',
    },
    {
      field: 'isRedeemed',
      title: 'Assigned',
    },
    {
      field: 'redeemedByUserEmail',
      title: 'Assignee',
    },
  ]

  return (
    <Container className={classes.container}>
      <Grid container justifyContent="space-between">
        <Grid className={classes.headerRow} item xs={12} md={6}>
          <Typography variant="h4">Licenses</Typography>
          <Typography variant="body2">
            Below is the list of the license codes assigned to your partnership
            account
          </Typography>
        </Grid>
      </Grid>
      {isLoading && <Loading />}
      {!isLoading && data && (
        <MaterialTable
          columns={columns}
          data={getTableData(data)}
          title=""
          options={{
            search: true,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50],
            rowStyle: {
              fontFamily: 'TT Norms Pro Medium, Arial',
            },
          }}
        />
      )}
    </Container>
  )
}

export default CouponCodes
