import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  orgName: {
    marginBottom: 0,
  },
  orgDesc: {
    marginBottom: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  topicTitle: {
    marginBottom: theme.spacing(3),
  },
  topicsList: {
    marginBottom: theme.spacing(4),
  },
  // Edit Step
  mainContainer: {
    paddingTop: theme.spacing(10),
  },
  innerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    paddingLeft: theme.spacing(10),
  },
  backButtonContainer: {
    position: 'absolute',
    left: 0,
  },
  iconBack: {
    fill: '#49454F',
  },
  wordLimitContainer: {
    background: '#313033',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(3.5),
  },
  wordLimitText: {
    color: '#F4EFF4',
  },
  termsSection: {
    width: '100%',
    marginTop: theme.spacing(6),
  },
  autocomplete: {
    marginBottom: theme.spacing(2),
  },
  saveButton: {
    marginTop: theme.spacing(4),
  },
  deleteButton: {
    marginTop: theme.spacing(6),
  },
  // common components
  listItem: {
    background: '#FFFBFE',
  },
}))

export default useStyles
