import React, { FC, useState, MouseEvent } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import { useHistory } from 'react-router-dom'
import { Hidden, Typography } from '@material-ui/core'

import LogoDrops from '../../assets/logo/intro-logo-kahoot.png'
import { useAuth } from '../../providers/auth'
import useStyles from './styles'
import { Routes } from '../../types'

interface DashboardHeaderProps {
  toggleDrawer: () => void
}

const DashboardHeader: FC<DashboardHeaderProps> = ({ toggleDrawer }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)
  const auth = useAuth()
  const history = useHistory()

  const open = Boolean(anchorEl)

  const handleMenu = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    auth.signout()
  }

  return (
    <AppBar className={classes.appBar} position="fixed" color="secondary">
      <Toolbar className={classes.toolbar}>
        <div className={classes.side}>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <img className={classes.logo}  src={LogoDrops} alt="Drops logo" onClick={() => history.push(Routes.Dashboard)} />
        </div>
        <div className={classes.side}>
          <Hidden smDown>
            <Typography variant="subtitle1">
              {auth.user?.email}
            </Typography>
          </Hidden>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
      <div className={classes.titleRow}>
        <Typography className={classes.title}variant="h5">Admin panel</Typography>
      </div>
    </AppBar>
  )
}

export default DashboardHeader