import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  headerRow: {
    paddingBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  paperCentered: {
    textAlign: 'center',
  },
  pieChartWrapper: {
    margin: '1.5rem 0',
  },
  pieChart: {
    maxWidth: 400,
  },
  languageDistributionCaption: {
    display: 'block'
  },
  pieChartInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  languageItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10
  },
  languageItemDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 6
  }
}))

export default useStyles