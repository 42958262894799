import { useEffect, useState } from 'react'
import InitialStep from './steps/InitialStep'
import EditTopicStep from './steps/EditTopicStep'
import { useAuth } from '../../providers/auth'
import { OrganizationTopic } from '@drops-developers/intersection'
import Loading from '../../components/Loading'
import { useAdminList, useCustomTopics } from '../../api/react-query'

export enum CustomTopicsSteps {
  InitialStep = 'InitialStep',
  EditTopicStep = 'EditTopicStep',
}

const CustomTopics = () => {
  const [step, setStep] = useState(CustomTopicsSteps.InitialStep)
  const [selectedTopic, setSelectedTopic] = useState<OrganizationTopic | null>(
    null
  )
  const { invokeCustomTopicsApi, invokeAdminApi } = useAuth()
  const { user } = useAuth()

  const { isLoading, data, isIdle, isError } = useCustomTopics(
    invokeCustomTopicsApi
  )
  const { isLoading: isAdminListLoading, data: adminListData } =
    useAdminList(invokeAdminApi)
  const topics = data?.topics ?? []
  const orgName = adminListData?.organizationTopicsTitle ?? ''

  const changeStep = (newStep: CustomTopicsSteps) => {
    setStep(newStep)
  }

  // Need this to update selected topic when data changes
  useEffect(() => {
    if (data?.topics && selectedTopic) {
      const updatedTopic = data.topics.find((x) => x.id === selectedTopic.id)
      setSelectedTopic(updatedTopic ?? null)
    }
  }, [data, selectedTopic])

  const goToChangeTopic = (topic: OrganizationTopic | null) => {
    setSelectedTopic(topic)
    setStep(CustomTopicsSteps.EditTopicStep)
  }

  if (user == null || user.email == null || isError) {
    return <div>An error occured, please relogin</div>
  }

  if (isLoading || isIdle || isAdminListLoading) {
    return <Loading />
  }

  if (step === CustomTopicsSteps.InitialStep) {
    return (
      <InitialStep
        orgName={orgName}
        goToChangeTopic={goToChangeTopic}
        topics={topics}
      />
    )
  }

  return (
    <EditTopicStep
      changeStep={changeStep}
      email={user.email}
      selectedTopic={selectedTopic}
      topics={topics}
    />
  )
}

export default CustomTopics
