import { useQuery } from 'react-query'
import {
  AdminApiAction,
  AdminApiQueries,
  CustomTopicsApiQueries,
} from '../types'
import { InvokeAdminListApi, InvokeCustomTopicsApi } from '../types/auth'

export const useCustomTopics = (invokeCustomTopicsApi: InvokeCustomTopicsApi) =>
  useQuery(CustomTopicsApiQueries.GetCustomTopics, async () => {
    const payload = {
      topics: [],
    }
    const response = await invokeCustomTopicsApi(payload)
    if (response instanceof Error) {
      throw new Error(response.message)
    }
    return response
  })

export const useAdminList = (invokeAdminApi: InvokeAdminListApi) =>
  useQuery(AdminApiQueries.GetList, async () => {
    const payload = {
      action: AdminApiAction.GetList,
    }
    const response = await invokeAdminApi(payload as any)
    if (response instanceof Error) {
      throw new Error(response.message)
    }
    return response
  })
