import React, { FC, useState } from 'react'
import { useTheme, useMediaQuery, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import DashboardHeader from '../../components/DashboardHeader'
import Navigation from '../../components/Navigation'
import { drawerWidth, titleRowHeight } from '../../constants/styles'

interface DashboardPageProps {

}

interface StyleProps {
  open: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,
    paddingTop: titleRowHeight,
  },
  content: props => ({
    paddingLeft: props.open ? drawerWidth : theme.spacing(7) + 1,
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    },
  }
  )
}))

const DashboardPage: FC<DashboardPageProps>  = ({ children }) => {
  const theme = useTheme()
  const isSM = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true
  })
  const [open, setOpen] = useState(isSM ? false : true)

  const styleProps: StyleProps = { open }
  const classes = useStyles(styleProps)

  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <div className="dashboard-page">
      <DashboardHeader toggleDrawer={toggleDrawer} />
      <Navigation open={open} toggleDrawer={toggleDrawer} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </div>
    </div>
  )
}

export default DashboardPage