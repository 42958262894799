import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3)
  },
  headerRow: {
    paddingBottom: theme.spacing(2)
  },
}))

export default useStyles