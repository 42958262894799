import React from 'react'
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import firebase from 'firebase/app'
import 'firebase/auth'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ProvideAuth } from './providers/auth'
import Login from './routes/login'
import Users from './routes/users'
import CouponCodes from './routes/coupon-codes'
import PrivateDashboardRoute from './components/PrivateDashboardRoute'
import UserEdit from './routes/users/UserEdit'
import { Routes } from './types'
import Dashboard from './routes/dashboard'
import Insights from './routes/insights'
import CustomTopics from './routes/custom-topics'

const firebaseConfig = {
  apiKey: 'AIzaSyCNJGHstW3JtRe4npqbxI9VKGFU3qslv-I',
  authDomain: 'drops-admin-panel.firebaseapp.com',
  projectId: 'drops-admin-panel',
  storageBucket: 'drops-admin-panel.appspot.com',
  messagingSenderId: '1031422588241',
  appId: '1:1031422588241:web:ac4ba7d4d1a60a711b4f70',
  measurementId: 'G-S0SP4GBVJX',
}

const firebaseConfigDev = {
  apiKey: "AIzaSyD2EsOj7r8k7xOmT06vEfPS0es7CxDo-Ps",
  authDomain: "drops-admin-panel-dev.firebaseapp.com",
  projectId: "drops-admin-panel-dev",
  storageBucket: "drops-admin-panel-dev.appspot.com",
  messagingSenderId: "761173669651",
  appId: "1:761173669651:web:51fd5bb71011b3f4454ce6"
};

if (!firebase.apps.length) {
  firebase.initializeApp(process.env.REACT_APP_ENVIRONMENT === "dev" ? firebaseConfigDev : firebaseConfig)
} else {
  firebase.app()
}

const theme = createTheme({
  typography: {
    fontFamily: 'TT Norms Pro Medium, Arial',
    h1: {
      fontFamily: 'TT Norms Pro Bold, Arial',
      marginBottom: '1rem'
    },
    h2: {
      fontFamily: 'TT Norms Pro Bold, Arial',
      marginBottom: '1rem'
    },
    h3: {
      fontFamily: 'TT Norms Pro Bold, Arial',
      marginBottom: '1rem'
    },
    h4: {
      fontFamily: 'TT Norms Pro Bold, Arial',
      marginBottom: '1rem'
    },
    h5: {
      fontFamily: 'TT Norms Pro Bold, Arial',
      marginBottom: '1rem'
    },
  },
  palette: {
    secondary: {
      main: "#481778"
    }
  }
})

const queryClient = new QueryClient()

function App() {
  return (
    <ProvideAuth>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <Route path={Routes.Login} exact={true}>
                <Login />
              </Route>
              <PrivateDashboardRoute path={Routes.Dashboard} exact={true}>
                <Dashboard />
              </PrivateDashboardRoute>
              <PrivateDashboardRoute path={Routes.Insights} exact={true}>
                <Insights />
              </PrivateDashboardRoute>
              <PrivateDashboardRoute path={Routes.UserList} exact={true}>
                <Users />
              </PrivateDashboardRoute>
              <PrivateDashboardRoute path={[Routes.UserCreate, Routes.UserUpdate + '/:id']} exact={true}>
                <UserEdit />
              </PrivateDashboardRoute>
              <PrivateDashboardRoute path={Routes.CouponCodeList} exact={true}>
                <CouponCodes />
              </PrivateDashboardRoute>
              <PrivateDashboardRoute path={Routes.CustomTopics} exact={true}>
                <CustomTopics />
              </PrivateDashboardRoute>
            </Switch>
          </Router>
          </QueryClientProvider>
      </ThemeProvider>
    </ProvideAuth>
  )
}

export default App
